import styled, { keyframes } from "styled-components";

const NavBodyAppearKeyframes = keyframes`
    0% {
        opacity: 0.5;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledNav = styled.nav`
    .nav__body {
        display: flex;
        align-items: center;
        ul {
            display: flex;
            justify-content: flex-end;
            list-style-type: none;
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                //border: 1px solid blue;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
                    height: 50px;

                    text-decoration: none;
                    white-space: nowrap;
                    overflow: hidden;
                    color: white;

                    padding: 15px;
                    font-weight: 400;
                    transition: 0.6s;
                    border-bottom: 1px solid #373957;

                    &:hover {
                        font-size: 18px;
                        border-bottom: 1px solid white;
                    }
                }
                .active {
                    font-size: 18px;
                    border-bottom: 1px solid white;
                }
            }
        }
        .lang-buttons-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 20px;
            img {
                width: auto;
                height: 15px;
                object-fit: contain;
            }
        }
    }
    .nav__toggler {
        display: none;
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .nav__body {
            display: ${(props) => (props.showNavBody ? "flex" : "none")};
            flex-direction: column;
            justify-content: space-around;

            width: 40%;
            height: 400px;

            position: absolute;
            top: 60px;
            right: 0;

            z-index: 1;

            background-color: #141630;
            border-bottom: 1px solid rgba(63, 69, 113, 1);
            box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
            -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
            -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);

            animation: ${NavBodyAppearKeyframes} 0.8s ease;

            ul {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                li {
                    width: 200px;
                }
            }
            .lang-buttons-row {
                justify-content: center;
                margin-left: 0px;
            }
        }
        .nav__toggler {
            width: 50px;
            height: 50px;

            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: transparent;
            border: 2px solid white;
            border-radius: 4px;
            padding: 10px;
        }
    }
    @media only screen and (max-width: 575px) {
        .nav__body {
            display: ${(props) => (props.showNavBody ? "flex" : "none")};
            flex-direction: column;
            justify-content: space-around;

            width: 100%;
            height: 400px;

            position: absolute;
            top: 50px;
            right: 0;

            z-index: 1;

            background-color: #141630;
            border-bottom: 1px solid rgba(63, 69, 113, 1);
            box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
            -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
            -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);

            animation: ${NavBodyAppearKeyframes} 0.8s ease;

            ul {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                li {
                    width: 200px;
                }
            }
            .lang-buttons-row {
                justify-content: center;
                margin-left: 0px;
            }
        }
        .nav__toggler {
            width: 40px;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: transparent;
            border: 2px solid white;
            border-radius: 4px;
            padding: 8px;
            font-size: 12px;
        }
    }
`;
