import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        overflow-y: scroll; 
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    body {
        width: 100%;
        margin: 0;
        background-color: #ffffff;
        font-family: "Georama", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
            monospace;
    }

    .w-100 {
        width: 100%;
    }
    .h-100 { 
        height: 100%;
    }
    .h-100vh { 
        height: 100vh;
    }
    .hauto {
        height: auto;
    }

    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }

    /* Flexbox Classes */
    .row {
        display: flex;
    }
    .wrap {
        flex-wrap: wrap;
    }
    .column {
        display: flex;
        flex-direction: column;
    }
    .align-c {
        align-items: center;
    }
    .justify-c {
        justify-content: center;
    }
    .justify-fs {
        justify-content: flex-start;
    }
    .justify-fe {
        justify-content: flex-end;
    }
    .justify-sb {
        justify-content: space-between;
    }
    .justify-sa {
        justify-content: space-around;
    }

    // - gap-x, where x - gap size in pixels.

    .gap-5 {
        gap: 5px;
    }
    .gap-8 {
        gap: 8px;
    }
    .gap-10 {
        gap: 10px;
    }
    .gap-13 {
        gap: 13px;
    }
    .gap-15 {
        gap: 15px;
    }
    .gap-20 {
        gap: 20px;
    }
    .gap-25 {
        gap: 25px;
    }
    .gap-30 {
        gap: 30px;
    }

    /* Margin && Padding */
    .mt-10 {
        margin-top: 10px;
    }
    .mt-20 {
        margin-top: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .py-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pt-10 {
        padding-top: 10px;
    }
    .pt-20 {
        padding-top: 20px;
    }
    .pt-40 {
        padding-top: 40px;
    }
    .pt-60 {
        padding-top: 60px;
    }

    /* Text customization */

    .text-center {
        text-align: center;
    }
    .text-uppercase {
        text-transform: uppercase;
    }
    .text-nodecoration {
        text-decoration: none;
    }
    .text-bold {
        font-weight: bold;
    }
    .text-justify {
        text-align: justify;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    // - c - Color
    .c-white {
        color: white;
    }
    .c-primary {
        color: #0865c1;
    }
    .c-secondary {
        color: #41464b;
    }
    .c-success {
        color: #4b5132;
    }
    .c-danger {
        color: #842029;
    }
    .c-warning {
        color: #7d4d03;
    }
    .c-info {
        color: #055160;
    }

    // - fs - Font Size
    .fs-13 {
        font-size: 13px;
    }
    .fs-14 {
        font-size: 14px;
    }
    .fs-15 {
        font-size: 15px;
    }
    .fs-16 {
        font-size: 16px;
    }

    // - lh - Line Height
    .lh-20 {
        line-height: 2.0;
    }
    .lh-25 {
        line-height: 2.5;
    }

    // - common shadows
    .blue-shadow {
        box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
        -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
        -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .xl-justify-c {
            justify-content: center;
        }
        .xl-row {
            flex-direction: row;
        }
        .xl-column {
            flex-direction: column;
        }
        .xl-align-c {
            align-items: center;
        }
        .xl-gap-30 {
            gap: 30px;
        }
        .xl-gap-10 {
            gap: 10px;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .lg-justify-c {
            justify-content: center;
        }
        .lg-row {
            flex-direction: row;
        }
        .lg-column {
            flex-direction: column;
        }
        .lg-align-c {
            align-items: center;
        }
        .lg-gap-30 {
            gap: 30px;
        }
        .lg-gap-10 {
            gap: 10px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .md-justify-c {
            justify-content: center;
        }
        .md-row {
            flex-direction: row;
        }
        .md-column {
            flex-direction: column;
        }
        .md-align-c {
            align-items: center;
        }
        .md-gap-30 {
            gap: 30px;
        }
        .md-gap-10 {
            gap: 10px;
        }
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .sm-justify-c {
            justify-content: center;
        }
        .sm-row {
            flex-direction: row;
        }
        .sm-column {
            flex-direction: column;
        }
        .sm-align-c {
            align-items: center;
        }
        .sm-gap-30 {
            gap: 30px;
        }
        .sm-gap-10 {
            gap: 10px;
        }
    }
    @media only screen and (max-width: 575px) {
        .m-justify-sb {
            justify-content: space-between;
        }
        .m-justify-c {
            justify-content: center;
        }
        .m-row {
            flex-direction: row;
        }
        .m-column {
            flex-direction: column;
        }
        .m-align-c {
            align-items: center;
        }
        .m-gap-30 {
            gap: 30px;
        }
        .m-gap-10 {
            gap: 10px;
        }
    }
`;
