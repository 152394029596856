import styled from "styled-components";

export const Main = styled.main`
    //width: 100%;
    //max-width: 1200px;

    width: 1200px;

    margin: 10px auto;
    color: #000000;
    background-color: transparent;
    padding: 0 20px 40px 20px;
    .heading {
        padding: 50px 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        width: 1120px;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 932px;
        .heading {
            padding: 45px 0;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        .heading {
            padding: 40px 0;
        }
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        .heading {
            padding: 35px 0;
        }
    }
    @media only screen and (max-width: 575px) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        .heading {
            padding: 30px 0;
        }
    }
`;
