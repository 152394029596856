import React, { useContext } from "react";
import { LanguageContext } from "../../App";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlasses } from "@fortawesome/free-solid-svg-icons";

// - IMAGES
import IMG_REACT_TS from "../../assets/images/react-typescript.png";

const StyledFocusedOnTemplate = styled.div`
    color: #171783;
    padding: 20px;
    font-size: 18px;

    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.2);
    .react-ts-img {
        height: 50px;
        width: auto;
        object-fit: contain;
    }
    @media only screen and (max-width: 575px) {
        padding: 5px;
        font-size: 15px;
    }
`;

const FocusedOnTemplate = () => {
    const { langState } = useContext(LanguageContext);

    let content = {
        eng: {
            focusOnText:
                "Currently I'm expanding my knowledge in React JS + TypeScript technical area",
        },
        lit: {
            focusOnText:
                "Šiuo metu aš plečiu savo žinias React JS + TypeScript techninėje srityje",
        },
    };
    content = langState.language === "ENG" ? content.eng : content.lit;
    return (
        <StyledFocusedOnTemplate className="row align-c gap-30 m-column">
            <FontAwesomeIcon icon={faGlasses} size="2x" />
            <p>{content.focusOnText}</p>
            <img
                className="react-ts-img"
                src={IMG_REACT_TS}
                alt="React JS + TypeScript"
            />
        </StyledFocusedOnTemplate>
    );
};

export default FocusedOnTemplate;
