import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    padding: 5px 0;
    font-size: 17px;
`;

const SolidInput = styled.input`
    width: 100%;
    max-width: 300px;
    height: 38px;
    padding: 5px 10px;
    font-size: 16px;
    font-family: "Georama", sans-serif;
    box-sizing: border-box;
    outline: none;
    border: 1px solid #50508a;
    border-radius: 5px;
    transition: 1s;
    &:focus {
        ${(props) =>
            props.incorrect
                ? css`
                      border: 1px solid #e25f4d;
                      background-color: #ffe7e6;
                  `
                : css`
                      border: 1px solid #1d41e0;
                      background-color: #e6f1ff;
                  `}
    }
    &:hover {
        ${(props) =>
            props.incorrect
                ? css`
                      background-color: #ffe7e6;
                  `
                : css`
                      background-color: #e6f1ff;
                  `}
    }
`;

const Input = forwardRef(
    ({ label, type, placeholder, incorrect, onDeselect }, ref) => {
        return (
            <InputGroup>
                {label && <Label>{label}</Label>}
                <SolidInput
                    type={type}
                    placeholder={placeholder}
                    ref={ref}
                    incorrect={incorrect}
                    onBlur={onDeselect}
                />
            </InputGroup>
        );
    }
);

export default Input;
