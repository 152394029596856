import React, { useContext } from 'react';
import { LanguageContext } from '../../App';
import styled from 'styled-components';

import SkillsTemplate from '../../components/templates/SkillsTemplate';
import FocusedOnTemplate from '../../components/templates/FocusedOnTemplate';
import { Main } from '../Main';

const SkillsTemplateColumns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const MySkillsView = () => {
  const { langState } = useContext(LanguageContext);

  let content = {
    eng: {
      pageHeading: 'Skills',
    },
    lit: {
      pageHeading: 'Įgūdžiai',
    },
  };
  content = langState.language === 'ENG' ? content.eng : content.lit;
  return (
    <Main>
      <h2 className="heading">{content.pageHeading}</h2>
      <SkillsTemplateColumns>
        {/* <FocusedOnTemplate /> */}
        <SkillsTemplate />
      </SkillsTemplateColumns>
    </Main>
  );
};

export default MySkillsView;
