import React, { useContext } from "react";
import { LanguageContext } from "../../App";

import styled from "styled-components";

import { Main } from "../Main";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faReact,
  faJsSquare,
  faNode,
  faBootstrap,
  faHtml5,
  faCss3Alt,
} from "@fortawesome/free-brands-svg-icons";

// -- COMPONENTS --

// -- IMAGES --

import SelfieImage from "../../assets/images/selfie/selfie.jpg";

const StyledSelfieImage = styled.img`
  /* width: 250px;
    height: 250px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;

  box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
  -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
  -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
`;

const StyledAboutView = styled(Main)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .about__selfie-wrapper {
    width: 250px;
    min-width: 250px;
    height: 250px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    gap: 30px;
    .about__selfie-wrapper {
      width: 200px;
      min-width: 200px;
      height: 200px;
    }
  }
  @media only screen and (max-width: 575px) {
    gap: 40px;
    .about__selfie-wrapper {
      width: 150px;
      min-width: 150px;
      height: 150px;
    }
  }
`;

/* 
I'm Deividas Šaltupis, a newbie, progressive web application front-end 
developer. At the
beginning of 2021 I decided to focus on web development,
so I joined Code Academy, where I have greatly expanded
my knowledge and skills in this particalar area. From
time to time I undertake occasional projects ordered by
customers. In the past I studied computer science in
Kaunas Technology University for a few years, so I have
learned the basic concepts and subjects in this scope.
*/

/*
Aš esu Deividas Šaltupis, jaunesnysis, nuolat tobulėjantis interneto aplikacijų vartotojo sąsajos programuotojas.
2021-ųjų metų pradžioje aš nusprendžiau užsiimti interneto aplikacijų kūrimu, todėl įstojau į CodeAcademy, 
kur ženkliai praplėčiau savo žinias ir įgyjau naujų įgūdžių šioje srityje. 
Atsiradus individualiems užsakymams užsiėmu interneto svetainių kūrimo projektais. 
Ankščiau, kurį laiką studijavau informatikos inžineriją Kauno technologijų universitete, įgyjau esminių žinių šioje srityje.
*/

const AboutView = () => {
  const { langState } = useContext(LanguageContext);

  let content = {
    eng: {
      pageHeading: "About me",
      about: {
        heading: "Hi,",
        text: `I'm Deividas Šaltupis, a junior web application developer. 
                At the beginning of 2021 I decided to focus on web development,
                so I joined Code Academy, where I have greatly expanded
                my knowledge and skills in this particalar area. 
                In the past I studied computer science in Kaunas Technology University for a few years, so I have
                learned the basic concepts and subjects in this scope. 
                Recently I have graduated Code Academy and from time to time I undertake occasional projects ordered by customers.`,
        subheadings: {
          education: `Code Academy<br />Graduate`,
          location: "Kaunas, Lithuania",
        },
      },
      techUseHeading: "Technologies I use",
    },
    lit: {
      pageHeading: "Apie mane",
      about: {
        heading: "Sveiki,",
        text: `Aš esu Deividas Šaltupis, jaunesnysis interneto aplikacijų programuotojas.
                2021-ųjų metų pradžioje aš nusprendžiau užsiimti interneto aplikacijų kūrimu, todėl įstojau į CodeAcademy, 
                kur ženkliai praplėčiau savo žinias ir įgyjau naujų įgūdžių šioje srityje. 
                Ankščiau, kurį laiką studijavau informatikos inžineriją Kauno technologijų universitete.
                Nesenai baigiau mokymus Code Academy ir pastaruoju metu užsiėmu interneto svetainių kūrimo projektais atsiradus individualiems užsakymams. 
                `,
        subheadings: {
          education: "Code Academy<br/>Absolventas",
          location: "Kaunas, Lietuva",
        },
      },
      techUseHeading: "Technologijos, kurias naudoju",
    },
  };
  content = langState.language === "ENG" ? content.eng : content.lit;

  return (
    <StyledAboutView>
      <h2 className="heading">{content.pageHeading}</h2>
      <div className="row gap-30 m-column m-align-c sm-column sm-align-c">
        <div className="about__selfie-wrapper">
          <StyledSelfieImage
            src={SelfieImage}
            alt="selfie"
            //className="w-100 h-100"
          />
        </div>
        <div className="column gap-15 m-gap-30 sm-gap-30">
          <h1>{content.about.heading}</h1>
          <p>{content.about.text}</p>
          <div className="row justify-sa">
            <div className="column align-c gap-15">
              <FontAwesomeIcon icon={faGraduationCap} size="2x" />
              <span
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: content.about.subheadings.education,
                }}
              ></span>
            </div>
            <div className="column align-c gap-15">
              <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" />
              <span
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: content.about.subheadings.location,
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <h3 className="heading text-center">{content.techUseHeading}</h3>
        <div className="row wrap justify-sa">
          <div className="p-30">
            <FontAwesomeIcon icon={faReact} size="3x" />
          </div>
          <div className="p-30">
            <FontAwesomeIcon icon={faJsSquare} size="3x" />
          </div>
          <div className="p-30">
            <FontAwesomeIcon icon={faNode} size="3x" />
          </div>
          <div className="p-30">
            <FontAwesomeIcon icon={faBootstrap} size="3x" />
          </div>
          <div className="p-30">
            <FontAwesomeIcon icon={faHtml5} size="3x" />
          </div>
          <div className="p-30">
            <FontAwesomeIcon icon={faCss3Alt} size="3x" />
          </div>
        </div>
      </div>
    </StyledAboutView>
  );
};

export default AboutView;
