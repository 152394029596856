import styled, { keyframes } from "styled-components";
import { rotateIn, bounceIn } from "react-animations";

export const StyledProjectRow = styled.div`
  display: flex;
  gap: 70px;
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    flex-direction: column;
    border-bottom: 1px solid rgba(14, 123, 224, 0.3);
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    border-bottom: 1px solid rgba(14, 123, 224, 0.3);
    padding-bottom: 20px;
  }
`;

export const ProjectImageOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(14, 123, 224, 0.8);
  color: white;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  opacity: 0;
`;

export const ProjectImageWrapper = styled.div`
  width: 500px;
  min-width: 500px;
  height: auto;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.5s ease-in-out;

  box-shadow: 0px 0px 80px 20px rgba(14, 123, 224, 0.3);
  -webkit-box-shadow: 0px 0px 80px 20px rgba(14, 123, 224, 0.3);
  -moz-box-shadow: 0px 0px 80px 20px rgba(14, 123, 224, 0.3);

  &:hover,
  :focus {
    ${ProjectImageOverlay} {
      opacity: 1;
    }
    box-shadow: 0px 0px 40px 40px rgba(14, 123, 224, 0.3);
    -webkit-box-shadow: 0px 0px 40px 40px rgba(14, 123, 224, 0.3);
    -moz-box-shadow: 0px 0px 40px 40px rgba(14, 123, 224, 0.3);
  }
  &:not(:hover, :focus) {
    ${ProjectImageOverlay} {
      opacity: 0;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 400px;
    min-width: 400px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: 600px;
    min-width: 600px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: 500px;
    min-width: 500px;
  }
  @media only screen and (max-width: 575px) {
    width: 100%;
    min-width: 100%;

    box-shadow: 0px 0px 40px 10px rgba(14, 123, 224, 0.3);
    -webkit-box-shadow: 0px 0px 40px 10px rgba(14, 123, 224, 0.3);
    -moz-box-shadow: 0px 0px 40px 10px rgba(14, 123, 224, 0.3);
    &:hover,
    :focus {
      box-shadow: 0px 0px 20px 20px rgba(14, 123, 224, 0.3);
      -webkit-box-shadow: 0px 0px 20px 20px rgba(14, 123, 224, 0.3);
      -moz-box-shadow: 0px 0px 20px 20px rgba(14, 123, 224, 0.3);
    }
  }
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`;

export const ProjectBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    gap: 30px;
  }
  @media only screen and (max-width: 575px) {
    gap: 20px;
  }
`;

export const ProjectStatusLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const RedLabel = styled.p`
  color: rgba(254, 72, 64, 1);
`;

export const BlueLabel = styled.p`
  color: rgba(14, 123, 224, 1);
`;

const rotateInAnimation = keyframes`
  ${rotateIn}
`;

const bounceInAnimation = keyframes`
  ${bounceIn}
`;

export const ProjectBodyTop = styled.div`
  display: flex;
  justify-content: space-between;
  ${ProjectStatusLabel} {
    .project-icon-working-on {
      color: rgba(254, 72, 64, 1);
      animation: 2s ${rotateInAnimation} infinite;
    }
    .project-icon-completed {
      color: rgba(14, 123, 224, 1);
      animation: 4s ${bounceInAnimation} infinite;
    }
  }
  @media only screen and (max-width: 575px) {
    ${ProjectStatusLabel} {
      justify-content: flex-end;
    }
  }
`;

export const ProjectBodyMiddle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ProjectBodyBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
  margin-top: auto;
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    margin-top: 0;
    padding-right: 30px;
  }
  @media only screen and (max-width: 575px) {
    margin-top: 0;
    padding-right: 30px;
  }
`;
