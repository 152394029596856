import React from "react";

const Closed = () => {
  return (
    <div className="row align-c justify-c h-100vh">
      <h1 className="text-center">Website is under reconstruction</h1>
    </div>
  );
};

export default Closed;
