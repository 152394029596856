import React from "react";
import styled from "styled-components";

const LangFigure = styled.div`
    background-color: #eff1f3;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    transition: 0.8s;
    width: 50%;
    img {
        width: 40px;
        height: auto;
        object-fit: contain;
    }
    &:hover {
        background-color: #d5dadf;
    }
`;

const LanguageFigure = ({ flagImg, text, action }) => {
    return (
        <LangFigure onClick={action}>
            <img src={flagImg} alt="Language flag" />
            <h3>{text}</h3>
        </LangFigure>
    );
};

export default LanguageFigure;
