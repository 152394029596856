import styled, { css } from "styled-components";

export const StyledProjectInModal = styled.div`
  padding: 10px;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #5b7fd7;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #2f55b2;
  }
  @media only screen and (max-width: 575px) {
    padding: 0;
    padding-right: 4px;
  }
`;

export const ProjectCarouselWrapper = styled.div`
  width: 100%;
  height: 400px;
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    height: 300px;
  }
  @media only screen and (max-width: 575px) {
    height: 210px;
  }
`;

export const ProjectModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
`;

export const CompletionLabel = styled.p`
  text-align: right;
  font-size: 14px;
  ${(props) =>
    props.completed
      ? css`
          color: rgba(14, 123, 224, 1);
        `
      : css`
          color: rgba(254, 72, 64, 1);
        `}
`;

export const LongDescription = styled.p`
  line-height: 1.7;
  @media only screen and (max-width: 575px) {
    font-size: 14px;
  }
`;

export const FeaturesText = styled.div`
  padding-left: 30px;
  @media only screen and (max-width: 575px) {
    font-size: 14px;
  }
`;

export const TechDetailsText = styled.div`
  padding-left: 30px;
  @media only screen and (max-width: 575px) {
    font-size: 14px;
  }
`;

export const IssuesText = styled.p`
  line-height: 1.5;
  font-size: 14px;
`;

export const LinksRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 40px;
  padding: 20px;
`;
