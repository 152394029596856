import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GlobalStyles } from "./styles/GlobalStyles.style";

// -- Header & Footer
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

// -- Views
import HomeView from "./views/Home/HomeView";
import AboutView from "./views/About/AboutView";
import PortfolioView from "./views/Portfolio/PortfolioView";
import MySkillsView from "./views/MySkills/MySkillsView";
import ContactView from "./views/Contact/ContactView";

import Closed from "./views/Closed/Closed";

// -- Components
import Modal from "./components/Common/Modal/Modal";

// -- Hooks
import useLanguageControl from "./hooks/useLanguageControl";
import useModal from "./hooks/useModal";

export const LanguageContext = React.createContext();
export const ModalContext = React.createContext();

function App() {
  const { langState, langDispatch } = useLanguageControl();
  const {
    displayModal,
    closeModal,
    isOpen,
    modalHeading,
    modalBody,
    modalActions,
  } = useModal();
  return (
    <>
      <LanguageContext.Provider value={{ langState, langDispatch }}>
        <ModalContext.Provider value={{ displayModal, closeModal, isOpen }}>
          <GlobalStyles />
          <div className="app">
            <Router>
              {/* <Header /> */}
              <Switch>
                <Route exact path="/" component={Closed} />
                {/* <Route exact path="/" component={HomeView} />
                <Route path="/about" component={AboutView} />
                <Route path="/portfolio" component={PortfolioView} />
                <Route path="/skills" component={MySkillsView} />
                <Route path="/contact" component={ContactView} /> */}
              </Switch>
              {/* <Footer /> */}
              {isOpen && (
                <Modal
                  heading={modalHeading.current}
                  actions={modalActions}
                  onClose={closeModal}
                >
                  {modalBody}
                </Modal>
              )}
            </Router>
          </div>
        </ModalContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}

export default App;
