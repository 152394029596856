import React, { useContext } from 'react';
import { LanguageContext } from '../../App';
import styled, { css } from 'styled-components';

const SkillsTable = styled.table`
  display: inline-block;
  .thead {
    //border-bottom: 1px solid grey;
    //width: calc(180px * 5);
    text-align: center;
    div {
      padding: 20px 0;
      width: 180px;
      font-size: 14px;
      border-bottom: 1px solid grey;
    }
  }
  .tbody {
    padding-top: 20px;
    .row {
      padding: 10px 0;
      font-size: 14px;
      .field-heading {
        width: 180px;
        text-align: right;
        padding: 0 30px 0 0;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .thead {
      div {
        padding: 10px;
        width: 120px;
      }
    }
    .tbody {
      .row {
        .field-heading {
          width: 120px;
          padding: 0 20px 0 0;
        }
      }
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .thead {
      div {
        padding: 0px;
        width: 80px;
        font-size: 12px;
      }
    }
    .tbody {
      .row {
        .field-heading {
          width: 80px;
          padding: 0 10px 0 0;
          font-size: 12px;
        }
      }
    }
  }
  @media only screen and (max-width: 575px) {
    overflow-x: auto;
    .thead {
      div {
        padding: 0px;
        width: 80px;
        min-width: 80px;
        font-size: 12px;
        padding: 10px 15px 10px 15px;
      }
    }
    .tbody {
      .row {
        .field-heading {
          width: 80px;
          min-width: 80px;
          padding: 0 10px 0 0;
          font-size: 12px;
        }
      }
    }
  }
`;

const ProgressBar = styled.div`
  @keyframes progressBarAnimation {
    100% {
      background-position: 0% 0%;
    }
  }

  background: repeating-linear-gradient(
    -45deg,
    ${(props) => props.color},
    ${(props) => props.color} 10px,
    ${(props) => props.subColor} 10px,
    ${(props) => props.subColor} 20px
  );
  background-size: 200% 200%;
  background-position: 100% 100%;
  animation: progressBarAnimation calc(${(props) => props.now} * 6s) linear
    infinite;
  ${(props) =>
    !props.animate &&
    css`
      animation: none;
    `}
  border-radius: 0 8px 8px 0;
  width: calc(180px * ${(props) => props.now});
  height: 20px;

  box-shadow: 3px 3px 2px 1px rgba(162, 167, 179, 0.3);
  -webkit-box-shadow: 3px 3px 2px 1px rgba(162, 167, 179, 0.3);
  -moz-box-shadow: 3px 3px 2px 1px rgba(162, 167, 179, 0.3);
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    width: calc(120px * ${(props) => props.now});
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    width: calc(80px * ${(props) => props.now});
  }
  @media only screen and (max-width: 575px) {
    width: calc(80px * ${(props) => props.now});
    min-width: calc(80px * ${(props) => props.now});
  }
`;

const SkillsTemplate = () => {
  const { langState } = useContext(LanguageContext);

  let content = {
    eng: {
      skillsTable: {
        headings: [
          'Technical field',
          'Basics',
          '> 3 month experience',
          '> 6 month experience',
          '> 12 month experience',
          '',
        ],
      },
    },
    lit: {
      skillsTable: {
        headings: [
          'Techninė sritis',
          'Pagrindai',
          '> 3 mėn. patirtis',
          '> 6 mėn. patirtis',
          '> 12 mėn. patirtis',
          '',
        ],
      },
    },
  };
  content = langState.language === 'ENG' ? content.eng : content.lit;
  return (
    <SkillsTable className="column">
      <div className="thead row text-center">
        <div>
          <span>{content.skillsTable.headings[0]}</span>
        </div>
        <div>
          <span>{content.skillsTable.headings[1]}</span>
        </div>
        <div>
          <span>{content.skillsTable.headings[2]}</span>
        </div>
        <div>
          <span>{content.skillsTable.headings[3]}</span>
        </div>
        <div>
          <span>{content.skillsTable.headings[4]}</span>
        </div>
        <div>
          <span>{content.skillsTable.headings[5]}</span>
        </div>
      </div>
      <div className="tbody">
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>HTML/CSS</span>
          </div>
          <ProgressBar
            now="4"
            max="5"
            color="#f56a4e"
            subColor="#e46248"
            animate
          >
            &nbsp;
          </ProgressBar>
        </div>
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>JavaScript</span>
          </div>
          <ProgressBar
            now="4"
            max="5"
            color="#edc915"
            subColor="#e7d164"
            animate
          >
            &nbsp;
          </ProgressBar>
        </div>
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>React JS</span>
          </div>
          <ProgressBar
            now="3"
            max="5"
            color="#61DAFB"
            subColor="#57cff0"
            animate
          >
            &nbsp;
          </ProgressBar>
        </div>
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>TypeScript</span>
          </div>
          <ProgressBar
            now="2"
            max="5"
            color="#2D79C7"
            subColor="#3083d6"
            animate
          >
            &nbsp;
          </ProgressBar>
        </div>
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>Node JS (back-end)</span>
          </div>
          <ProgressBar now="2" max="5" color="#87BF00" subColor="#95c721">
            &nbsp;
          </ProgressBar>
        </div>
        <div className="row align-c text-center">
          <div className="field-heading">
            <span>Laravel</span>
          </div>
          <ProgressBar now="1" max="5" color="#e7452f" subColor="#fae2df">
            &nbsp;
          </ProgressBar>
        </div>
      </div>
    </SkillsTable>
  );
};

export default SkillsTemplate;
