import styled, { keyframes } from "styled-components";

const logoSizingAnimation = keyframes`
    0% {
        transform: scale(0.5, 0.5);
        opacity: 0.5;
    }
    80% {
        transform: scale(1.1, 1.1);
        opacity: 1.2;
    }
    100% {
        transform: scale(1.0, 1.0);
        opacity: 1.0;
    }
`;

export const BlueHeader = styled.header`
    background-color: #141630;
    width: 100%;
    display: flex;

    box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
    -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
    -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);

    border-bottom: 1px solid rgba(63, 69, 113, 1);

    .header-inner-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //width: 100%;
        width: 1200px;
        height: 80px;
        //max-width: 1200px;
        margin: 0 auto;
        .logo-wrapper {
            width: auto;
            height: 60px;
            .logo-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
                animation: ${logoSizingAnimation} 3s linear 1;
                transition: 1s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.1, 1.1);
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .header-inner-wrapper {
            width: 1120px;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .header-inner-wrapper {
            width: 932px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-inner-wrapper {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .header-inner-wrapper {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            height: 60px;
            .logo-wrapper {
                height: 50px;
            }
        }
    }
    @media only screen and (max-width: 575px) {
        .header-inner-wrapper {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            height: 50px;
            .logo-wrapper {
                height: 45px;
            }
        }
    }
`;
