import styled, { css } from "styled-components";

export const BasicButtonStyle = css`
    display: inline-block;
    border: none;
    cursor: pointer;

    padding: 7px 10px;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Georama", sans-serif;
    transition: 0.8s;
    max-width: 200px;
`;

export const SolidButton = styled.button`
    ${BasicButtonStyle}
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.bgColor};
    border: 1px solid ${(props) => props.border};
    ${(props) =>
        props.active &&
        css`
            background-color: ${(props) => props.hoverBgColor};
            border: 1px solid ${(props) => props.hoverBorder};
        `}
    &:hover {
        background-color: ${(props) => props.hoverBgColor};
        border: 1px solid ${(props) => props.hoverBorder};
    }
`;

export const OutlineButton = styled.button`
    ${BasicButtonStyle}
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.bgColor};
    border: 1px solid ${(props) => props.border};
    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.hoverBorder};
            border: 1px solid ${(props) => props.hoverBorder};
        `}
    &:hover {
        color: ${(props) => props.hoverBorder};
        border: 1px solid ${(props) => props.hoverBorder};
    }
`;

export const UnderlineButton = styled.button`
    ${BasicButtonStyle}
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.bgColor};
    border-bottom: 1px solid ${(props) => props.border};
    border-radius: 0px;
    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.hoverBorder};
            border-bottom: 1px solid ${(props) => props.hoverBorder};
        `}
    &:hover {
        color: ${(props) => props.hoverBorder};
        border-bottom: 1px solid ${(props) => props.hoverBorder};
    }
`;

export const LinkButton = styled.button`
    ${BasicButtonStyle}
`;
