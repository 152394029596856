import styled from "styled-components";
import { Main } from "../Main";

export const StyledHomeView = styled(Main)`
    padding: 20px;
    .home__summary-image-wrapper {
        width: 250px;
        img {
            object-fit: contain;
        }
    }
    .home__summary-text-wrapper {
        margin-left: 50px;
        h2 {
            //padding: 0px 0;
        }
        p {
            //padding: 40px 0;
        }
        .home__summary-btn-row {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }
    @media only screen and (max-width: 575px) {
        .home__summary-text-wrapper {
            margin: 0;
            .home__summary-btn-row {
                gap: 10px;
            }
        }
    }
`;

export const HomeHeader = styled.div`
    box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
    -webkit-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);
    -moz-box-shadow: 0px 1px 11px 1px rgba(93, 102, 128, 0.63);

    border-bottom: 1px solid rgba(63, 69, 113, 1);
    img {
        height: 300px;
    }
    .home-header__layer {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background: rgb(34, 45, 126);
        background: radial-gradient(
            circle,
            rgba(34, 45, 126, 0.9) 0%,
            rgba(34, 45, 126, 0.5) 100%
        );
        h1 {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-shadow: 2px 2px 2px rgba(15, 15, 15, 0.9);
            white-space: nowrap;
            padding: 0;
            color: #ffffff;
        }
    }
    @media only screen and (max-width: 575px) {
        .home-header__layer {
            h1 {
                white-space: normal;
                font-size: 24px;
                top: 100px;
                left: 0;
                right: 0;
                bottom: 0;
                transform: none;
            }
        }
    }
`;
