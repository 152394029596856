import styled, { css, keyframes } from "styled-components";

export const StyledCarousel = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
`;

const BasicTogglerStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  transition: 0.7s;
  height: 70px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const LeftToggler = styled.button`
  ${BasicTogglerStyle}
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const RightToggler = styled.button`
  ${BasicTogglerStyle}
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export const SlideNumberLabel = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
  padding: 0px 10px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
`;

const fadeInKeyframes = keyframes`
  from {opacity: .1} 
  to {opacity: 1}
`;

export const CarouselSlide = styled.div`
  display: ${(props) => (props.active ? "block" : "none")};
  position: relative;
  width: 100%;
  height: 100%;

  animation: 1.5s ${fadeInKeyframes};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
`;

export const SlideDotsRow = styled.div`
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  gap: 10px;
`;

export const SlideDot = styled.div`
  height: 15px;
  width: 15px;

  border-radius: 50%;
  transition: background-color 0.8s ease;

  ${(props) =>
    props.active
      ? css`
          background-color: rgba(0, 0, 0, 0.9);
        `
      : css`
          background-color: rgba(0, 0, 0, 0.5);
        `}
`;

export const SlideOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3) inset;
  -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.3) inset;
`;
