import styled from "styled-components";

export const BlueFooter = styled.footer`
    background-color: #141630;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    padding: 30px 0;

    box-shadow: 0px -1px 11px 2px rgba(93, 102, 128, 0.63);
    -webkit-box-shadow: 0px -1px 11px 2px rgba(93, 102, 128, 0.63);
    -moz-box-shadow: 0px -1px 11px 2px rgba(93, 102, 128, 0.63);

    border-top: 1px solid rgba(63, 69, 113, 1);

    .footer-inner-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        //width: 100%;
        width: 1200px;

        .footer__social-link-icon {
            transition: 0.8s;
            transform: scale(0.8, 0.8);
            &:hover {
                transform: scale(1, 1);
            }
        }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .footer-inner-wrapper {
            width: 1120px;
        }
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .footer-inner-wrapper {
            width: 932px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        .footer-inner-wrapper {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
        .footer-inner-wrapper {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    @media only screen and (max-width: 575px) {
        .footer-inner-wrapper {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;

            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            text-align: center;
        }
    }
`;
