import { useReducer, useEffect } from "react";
import {
    localStorageGetItem,
    localStorageSetItem,
} from "../utils/localStorage";

const useLanguageControl = () => {
    const initialState = { language: "ENG" };
    const reducer = (state, action) => {
        switch (action.type) {
            case "SET_LANGUAGE":
                localStorageSetItem("language", action.payload);
                return { language: action.payload };
            case "SET_SAVED_LANGUAGE":
                return { language: action.payload };
            default:
                return state;
        }
    };
    const [langState, langDispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        let savedLanguage = localStorageGetItem("language");
        if (savedLanguage)
            langDispatch({
                type: "SET_SAVED_LANGUAGE",
                payload: savedLanguage,
            });
    }, []);
    return { langState, langDispatch };
};

export default useLanguageControl;
