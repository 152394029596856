import styled from "styled-components";

export const PortfolioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

export const PortfolioSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

export const PortfolioSectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
