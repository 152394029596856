import React, { useContext } from "react";
import styled from "styled-components";
import LanguageFigure from "../Common/Figure/LanguageFigure";

import { LanguageContext } from "../../App";

import EngFlagImg from "../../assets/images/flags/eng.png";
import LitFlagImg from "../../assets/images/flags/lit.png";

const LanguageSelectWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  width: 500px;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

const LanguageSelectTemplate = ({ closeModal }) => {
  const { langDispatch } = useContext(LanguageContext);
  const handleLanguageSelect = (languageTag) => {
    langDispatch({ type: "SET_LANGUAGE", payload: languageTag });
    closeModal();
  };
  return (
    <LanguageSelectWrapper>
      <LanguageFigure
        text="English"
        flagImg={EngFlagImg}
        action={() => handleLanguageSelect("ENG")}
      />
      <LanguageFigure
        text="Lietuvių"
        flagImg={LitFlagImg}
        action={() => handleLanguageSelect("LIT")}
      />
    </LanguageSelectWrapper>
  );
};

export default LanguageSelectTemplate;
