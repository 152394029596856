import styled, { css, keyframes } from "styled-components";

const AlertAppearKeyframes = keyframes`
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const BasicAlertStyle = css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 18px;
    border-radius: 8px;
    font-size: 15px;
    gap: 10px;
    animation: ${AlertAppearKeyframes} 0.8s ease;
`;

export const PrimaryAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #9fb9e0;
    background-color: #cfe2ff;
    color: #0865c1;
`;

export const SecondaryAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #bbbdbf;
    background-color: #e2e3e5;
    color: #41464b;
`;

export const SuccessAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #a3c2b4;
    background-color: #d1e7dd;
    color: #4b5132;
`;

export const DangerAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #d4a3a7;
    background-color: #f8d7da;
    color: #842029;
`;

export const WarningAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #d1c394;
    background-color: #fff3cd;
    color: #7d4d03;
`;

export const InfoAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #a1c9d1;
    background-color: #cff4fc;
    color: #055160;
`;

export const LightAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #ededed;
    background-color: #fefefe;
    color: #636464;
`;

export const DarkAlert = styled.div`
    ${BasicAlertStyle}
    border: 1px solid #ababb3;
    background-color: #d3d3d4;
    color: #141619;
`;
